import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MiotLogo } from '../assets/images/MinervaIoT Logo.svg';



export default function Header() {
    const navigate = useNavigate();
    const buttons = [
        // { label: "Switch Language", url: "/language" },
        // { label: "Settings", url: "/settings" },
        // { label: "Log alerts", url: "/log-alerts" },
        // { label: "Categorised", url: "/categorised" },
        { label: "Main Dashboard", url: "/dashboard" },
    ];
    const [activeButton, setActiveButton] = React.useState(buttons.length - 1);

    React.useEffect(() => {
        const currentUrl = window.location.pathname;
        const index = buttons.findIndex(button => button.url === currentUrl);
        setActiveButton(index);
    }, []);

    return (
        <>
            <Box display={"flex"} p={1.5} style={{ paddingLeft: '30px' }}  >
                <MiotLogo />
                <Box item flexGrow={1} sx={{
                    marginRight: '54px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    gap: '16px',
                }} >
                    {buttons.map((button, index) => (
                        <Button
                            key={index}
                            onClick={() => { setActiveButton(index); navigate(button.url) }}
                            style={{
                                color: activeButton !== index ? "#707070" : "#05568D", textTransform: 'none'
                            }}
                        >
                            {button.label}
                        </Button>
                    ))}

                </Box>
            </Box>
        </>
    );
};