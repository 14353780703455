import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Route, Routes } from "react-router-dom";
import DashBoard from "./components/dashBoard";
import Layout from "./components/layout";
import Categorised from './pages/categorized';


export default function AppRoutes() {
    return (
        <Layout>
            <PerfectScrollbar style={{ height: '100vh' }}>
                <Routes>
                    <Route exact path="/" element={<DashBoard />} />
                    <Route exact path="/dashboard" element={<DashBoard />} />
                    {/* <Route exact path="/categorised" element={<Categorised />} /> */}
                </Routes>
            </PerfectScrollbar>
        </Layout >
    );
}