import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from 'clsx';
import React from 'react';
import FilterToolBar from '../components/filterToolBar';


const useStyles = makeStyles({
    datePicker: {
        width: '166px',
    },
    datepickerOutline: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
        },
        "& .MuiSvgIcon-root": {
            color: "#fff"
        }
    }
})


export default function Categorised() {
    const classes = useStyles();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [data, setData] = React.useState([
        {
            "name": 'user'
        },
        {
            "name": 'company'
        },
        {
            "name": 'device'
        },
        {
            "name": 'event'
        },

    ])


    const formatToDate = (str) => {
        if (str) {
            var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        } else {
            return date;
        }
    }

    return (
        <>
            <FilterToolBar >
                <Box mt={2} pr={2.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            className={clsx(classes.datePicker, 'datepickerStyles')}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "#fff"
                                }
                            }}
                            label={('Start Date')}
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(formatToDate(newValue));
                            }}
                            renderInput={(params) => <TextField  {...params} onKeyDown={(e) => { e.preventDefault(); }} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box mt={2} pr={2.5} >
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "#fff"
                                }
                            }}
                            className={clsx(classes.datePicker, classes.datepickerOutline, 'datepickerStyles')}
                            label={('End Date')}
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(formatToDate(newValue));
                            }}
                            renderInput={(params) => <TextField style={{ color: 'white' }} {...params} onKeyDown={(e) => { e.preventDefault(); }} />}
                        />
                    </LocalizationProvider>
                </Box>
            </FilterToolBar>


        </>
    )
}