import { Grid } from "@mui/material";
import React from "react";

export default function FilterToolBar(props) {
    return (
        <>
            <Grid container justifyContent="flex-end" pb={1} sx={{ backgroundColor: '#1F78B4', zIndex: '1999', paddingRight: '19px', marginTop: '60px' }} position={'fixed'}>
                {props.children}
            </Grid>
        </>
    );
}