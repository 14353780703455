import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import ReactECharts from 'echarts-for-react';
import React from "react";
import companyService from "../actions/companyService";

export default function CompaniesChart(props) {
    const [dateRange, setDateRange] = React.useState('daily');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [option, setOption] = React.useState({
        grid: {
            top: 45,
            bottom: 50,
            left: 60,
            right: 60
        },
        tooltip: {
            trigger: 'axis',
            formatter: '{b} <ul style="padding-left: 12px;margin: 1px;"><li>Companies  {c}</ul></li>'
        },
        calculable: true,
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}'
            }
        },
        series: [
            {
                name: 'Companies',
                type: 'bar',
                step: 'start',
                color: "#1F78B4",
                data: []
            }
        ]
    });

    React.useEffect(() => {
        let userData;
        if ((props.fromDate !== null && props.toDate === null) || (props.fromDate === null && props.toDate !== null)) {
            
        }  else {
            const payload = {
                query: dateRange,
                isTotal: props.isTotal,
                fromDate: props.fromDate,
                toDate: props.toDate,
                applicationId: props.applicationId,
            };
            companyService.getCompanyList(payload).then((data) => {
                userData = data.data ? data.data : [];
                const maxValue = Math.max(...userData.map((user) => (user.count)));
                // const splitNUmber = maxValue < 1000 ? 1 : maxValue >= 10000 ? 10 : 10;
                setOption({
                    ...option,
                    xAxis: {
                        type: 'category',
                        // boundaryGap: false,
                        axisLabel: {
                            rotate: 45,
                            textStyle: {
                                fontSize: 7,
                            },
                        },
                        data: dateRange === 'daily' ?
                            userData.map(item => item.day).reverse() :
                            dateRange === 'monthly' ? userData.map(item => (
                                item.month.slice(0, 4) + '-' + monthNames[item.month.slice(-2) - 1])).reverse() :
                                dateRange === 'quarterly' ? userData.map(item => item.quarter).reverse() : userData.map(item => item.year).reverse()
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        },
                        // splitNumber: splitNUmber,
                    },
                    series: [
                        {
                            name: 'Companies',
                            type: 'bar',
                            step: 'start',
                            color: "#1F78B4",
                            itemStyle: {
                                color: '#1F78B4'
                            },
                            barWidth: 10,
                            data: dateRange === 'daily' ?
                                userData.map(item => item.count) :
                                dateRange === 'monthly' ? userData.map(item => item.count) :
                                    dateRange === 'quarterly' ? userData.map(item => item.count) : userData.map(item => item.count),
                            markPoint: {
                                data: [
                                    { type: 'max', name: 'Max', label: { fontSize: 8, formatter: '{c}' } },
                                    { type: 'min', name: 'Min', label: { fontSize: 8, formatter: '{c}' } },
                                ]
                            }
                        }
                    ]
                })
            })
        }
    }, [dateRange, props.fromDate, props.toDate, props.applicationId]);

    return (
        <div>
            <Box display='flex' flexDirection='row'>
                <Box flexGrow={1}>
                    <Typography gutterBottom variant='h6' component='h6' style={{ fontSize: '16px', paddingLeft: '14px', color: '#05568D', opacity: '1px', marginLeft: '3px', marginTop: '15px' }}>
                        {('Companies')}
                    </Typography>
                </Box>
                <Box p={1} width='90px'>
                    <FormControl variant='outlined' size='small' fullWidth>
                        <Select
                            value={dateRange}
                            labelId='demo-simple-select-outlined-label'
                            id='demo-simple-select-outlined'
                            onChange={(e) => setDateRange(e.target.value)}
                            style={{ color: "#707070", opacity: '1px', borderRadius: '20px', fontSize: '12px' }}
                        >
                            <MenuItem value={'daily'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Daily')}</MenuItem>
                            <MenuItem value={'monthly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Monthly')}</MenuItem>
                            <MenuItem value={'quarterly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Quarterly')}</MenuItem>
                            <MenuItem value={'yearly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Yearly')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <ReactECharts option={option} />
        </div>
    )
}