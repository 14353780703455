import axios from "axios";


const buildQuery = (filter) => {
  let res = `query=${filter.query}&isTotal=${filter.isTotal}`;

  if (filter.applicationId !== '') {
    res = res + `&applicationId=${filter.applicationId}`;
  }
  if (filter.fromDate !== null) {
    res = res + `&fromDate=${filter.fromDate}`;
  }
  if (filter.toDate !== null) {
    res = res + `&toDate=${filter.toDate}`;
  }
  return res;
}

const getCompanyList = (filter) => {
  return axios.get(process.env.REACT_APP_SERVER_URL + `/companies/filter?` + buildQuery(filter));
};

const getApplicationIdList = () => {
  return axios.get(process.env.REACT_APP_SERVER_URL + '/application/ids');
};

const companyService = {
  getCompanyList,
  getApplicationIdList,
};
export default companyService;
