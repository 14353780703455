import axios from "axios";

const buildQuery = (filter) => {
  let res = `query=${filter.query}&isTotal=${filter.isTotal}`;

  if (filter.applicationId !== '') {
    res = res + `&applicationId=${filter.applicationId}`;
  }
  if (filter.fromDate !== null) {
    res = res + `&fromDate=${filter.fromDate}`;
  }
  if (filter.toDate !== null) {
    res = res + `&toDate=${filter.toDate}`;
  }

  return res;
}

const getUserList = (filter) => {
  return axios.get(process.env.REACT_APP_SERVER_URL + `/users/filter?` + buildQuery(filter));
};
const userservice = {
  getUserList,
};
export default userservice;
