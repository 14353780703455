import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import ReactECharts from 'echarts-for-react';
import React from "react";

export default function EventsChart() {
    const [dateRange, setDateRange] = React.useState('Daily');
    const [eventsDaily, setEventsDaily] = React.useState([
        { day: '28', normal: 41, warning: 22, critical: 11 },
        { day: '27', normal: 37, warning: 16, critical: 16 },
        { day: '26', normal: 55, warning: 42, critical: 14 },
        { day: '25', normal: 25, warning: 37, critical: 24 },
        { day: '24', normal: 12, warning: 20, critical: 19 },
        { day: '23', normal: 10, warning: 12, critical: 15 },
        { day: '22', normal: 30, warning: 27, critical: 23 },
        { day: '21', normal: 45, warning: 42, critical: 14 },
        { day: '20', normal: 55, warning: 17, critical: 12 },
        { day: '19', normal: 48, warning: 42, critical: 16 },
    ]);

    const [events, setEvents] = React.useState([
        { month: 'Apr', normal: 51, warning: 42, critical: 21 },
        { month: 'Mar', normal: 17, warning: 26, critical: 36 },
        { month: 'Feb', normal: 45, warning: 32, critical: 24 },
        { month: 'Jan', normal: 35, warning: 17, critical: 14 },
        { month: 'Dec', normal: 21, warning: 42, critical: 13 },
    ]);

    const [eventsQuarterly, setEventsQuarterly] = React.useState([
        { quarter: '2023-2', normal: 21, warning: 20, critical: 31 },
        { quarter: '2023-1', normal: 17, warning: 21, critical: 16 },
        { quarter: '2022-4', normal: 15, warning: 32, critical: 24 },
        { quarter: '2022-3', normal: 25, warning: 30, critical: 34 },
        { quarter: '2022-2', normal: 31, warning: 12, critical: 19 },
    ]);

    const [eventsYearly, setEventsYearly] = React.useState([
        { year: '2023', normal: 11, warning: 20, critical: 31 },
        { year: '2022', normal: 47, warning: 29, critical: 26 },
        { year: '2021', normal: 19, warning: 41, critical: 34 },

    ]);

    const [option, setOption] = React.useState({
        grid: {
            top: 35,
            bottom: 30
        },
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
        },
        yAxis: {
            type: 'value',
        },
        legend: {
            data: ['Normal', 'Warning', 'Critical'],
            align: 'left',
            right: '1%',
        },
        series: [
            {
                type: 'line',
                smooth: true,
                color: "#c6ccc8",
                data: []
            }
        ],
    });

    React.useEffect(() => {
        setOption({
            ...option,
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // data: events.map(item => item.month).reverse()
                data: dateRange === 'Daily' ?
                    eventsDaily.map(item => item.day).reverse() :
                    dateRange === 'Monthly' ? events.map(item => item.month).reverse() :
                        dateRange === 'Quarterly' ? eventsQuarterly.map(item => item.quarter).reverse() : eventsYearly.map(item => item.year).reverse()
            },
            series: [
                {
                    name: 'Normal',
                    type: 'line',
                    smooth: true,
                    color: "#55AAD6",
                    symbol: 'circle',
                    symbolSize: 6,
                    data: dateRange === 'Daily' ?
                        eventsDaily.map(item => item.normal).reverse() :
                        dateRange === 'Monthly' ? events.map(item => item.normal).reverse() :
                            dateRange === 'Quarterly' ? eventsQuarterly.map(item => item.normal).reverse() : eventsYearly.map(item => item.normal).reverse(),
                    endLabel: {
                        show: true,
                        formatter: function (params) {
                            return params.value;
                        }
                    },
                },
                {
                    name: 'Warning',
                    type: 'line',
                    smooth: true,
                    color: "#F59B41",
                    symbol: 'circle',
                    symbolSize: 6,
                    data: dateRange === 'Daily' ?
                        eventsDaily.map(item => item.warning).reverse() :
                        dateRange === 'Monthly' ? events.map(item => item.warning).reverse() :
                            dateRange === 'Quarterly' ? eventsQuarterly.map(item => item.warning).reverse() : eventsYearly.map(item => item.warning).reverse(),
                    endLabel: {
                        show: true,
                        formatter: function (params) {
                            return params.value;
                        }
                    },
                },
                {
                    name: 'Critical',
                    type: 'line',
                    smooth: true,
                    color: "#FF2727",
                    symbol: 'circle',
                    symbolSize: 6,
                    data: dateRange === 'Daily' ?
                        eventsDaily.map(item => item.critical).reverse() :
                        dateRange === 'Monthly' ? events.map(item => item.critical).reverse() :
                            dateRange === 'Quarterly' ? eventsQuarterly.map(item => item.critical).reverse() : eventsYearly.map(item => item.critical).reverse(),
                    endLabel: {
                        show: true,
                        formatter: function (params) {
                            return params.value;
                        }
                    },
                }
            ],
        })
    }, [dateRange]);

    return (
        <div>
            <Box display='flex' flexDirection='row'>
                <Box flexGrow={1}>
                    <Typography gutterBottom variant='h6' component='h6' style={{ fontSize: '16px', paddingLeft: '14px', color: '#05568D', opacity: '1px', marginLeft: '3px', marginTop: '15px' }}>
                        {('Events')}
                    </Typography>
                </Box>
                <Box p={1} width='90px'>
                    <FormControl variant='outlined' size='small' fullWidth>
                        <Select
                            value={dateRange}
                            labelId='demo-simple-select-outlined-label'
                            id='demo-simple-select-outlined'
                            onChange={(e) => setDateRange(e.target.value)}
                            style={{ color: "#707070", opacity: '1px', borderRadius: '20px', fontSize: '12px' }}
                        >
                            <MenuItem value={'Daily'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Daily')}</MenuItem>
                            <MenuItem value={'Monthly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Monthly')}</MenuItem>
                            <MenuItem value={'Quarterly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Quarterly')}</MenuItem>
                            <MenuItem value={'Yearly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Yearly')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <ReactECharts option={option} />
        </div>
    )
}