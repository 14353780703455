import React from 'react';
import Header from './header';
import { AppBar, Grid } from '@mui/material';

const Layout = ({ children }) => {
  return (
    <Grid container>
      <AppBar position="fixed" style={{ backgroundColor: '#FFFFFF' }}>
        <Header />
      </AppBar>
      <Grid xs={12} >{children}</Grid>
    </Grid>
  );
};

export default Layout;
