import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from 'clsx';
import dayjs from "dayjs";
import React, { useEffect } from "react";
import companyService from "../actions/companyService";
import ActiveUsersChart from "../pages/activeUsersChart";
import CompaniesChart from "../pages/companiesChart";
import EventsChart from "../pages/eventsChart";
import OnlineDevicesChart from "../pages/onlineDevicesChart";
import RegisteredDeviceChart from "../pages/registeredDeviceChart";
import TotalActiveUsersChart from "../pages/totalActiveUsersChart";
import TotalCompaniesChart from "../pages/totalCompaniesChart";
import TotalOnlineDevicesChart from "../pages/totalOnlineDevicesChart";
import TotalRegisteredDeviceChart from "../pages/totalRegisteredDeviceChart";
import FilterToolBar from "./filterToolBar";

const useStyles = makeStyles({
    datePicker: {
        width: '166px',
    },
    datepickerOutline: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
        },
        "& .MuiSvgIcon-root": {
            color: "#fff"
        }
    }
})

const formatToDate = (str) => {
    if (str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    } else {
        return date;
    }
}
const today = formatToDate(new Date());
const last30thDay = formatToDate((new Date(today)).getTime() - (30 * 24 * 60 * 60 * 1000));

export default function DashBoard() {
    const classes = useStyles();
    const [startDate, setStartDate] = React.useState(dayjs(last30thDay));
    const [endDate, setEndDate] = React.useState(dayjs(today));
    const [applId, setApplId] = React.useState('All Applications');
    const [applicationData, setApplicationData] = React.useState([]);

    useEffect(() => {
        companyService.getApplicationIdList().then((data) => setApplicationData(data.data));
    }, []);

    let isDisplay = (applId === '' || applId === 'All Applications');

    return (
        <>
            <FilterToolBar >
                <Box mt={2} pr={3}>
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel >{'Applications'}</InputLabel>
                        <Select
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "#fff"
                                }
                            }}
                            value={applId}
                            label={'Applications'}
                            onChange={(e) => setApplId(e.target.value)}
                            style={{ borderRadius: '10px', fontSize: '12px', width: '166px', height: '40px', color: '#fff' }}
                        >
                            <MenuItem value={"All Applications"} style={{ fontSize: '12px' }}>{"All Applications"}</MenuItem>
                            {applicationData.map((app, index) => (
                                <MenuItem key={index} value={app.id} style={{ fontSize: '12px' }}>{app.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box mt={2} pr={2.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            type='date'
                            className={clsx(classes.datePicker, 'datepickerStyles')}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "#fff"
                                }
                            }}
                            label={('Start Date')}
                            value={startDate}
                            onChange={(newValue) => {
                                if (newValue === 'NaN-aN-aN' || newValue === undefined || newValue === null) {
                                    setStartDate(null);
                                } else {
                                    setStartDate(formatToDate(newValue));
                                }
                            }}
                            renderInput={(params) => <TextField  {...params} onKeyDown={(e) => { e.preventDefault(); }} type="date" />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box mt={2} pr={2.5} >
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "#fff"
                                }
                            }}
                            className={clsx(classes.datePicker, classes.datepickerOutline, 'datepickerStyles')}
                            label={('End Date')}
                            value={endDate}
                            onChange={(newValue) => {
                                if (newValue === 'NaN-aN-aN' || newValue === undefined || newValue === null) {
                                    setEndDate(null);
                                } else {
                                    setEndDate(formatToDate(newValue));
                                }
                            }}
                            renderInput={(params) => <TextField style={{ color: 'white' }} {...params} onKeyDown={(e) => { e.preventDefault(); }} />}
                        />
                    </LocalizationProvider>
                </Box>
            </FilterToolBar>
            <Grid container p={2} style={{ marginTop: '140px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                    <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <ActiveUsersChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={false} />
                        </Grid>
                    </Grid>

                    {isDisplay && <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <OnlineDevicesChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={false} />
                        </Grid>
                    </Grid>
                    }

                    {isDisplay && <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <RegisteredDeviceChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={false} />
                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <CompaniesChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={false} />
                        </Grid>
                    </Grid>
                    {isDisplay && <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <EventsChart />
                        </Grid>
                    </Grid>
                    }
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <TotalActiveUsersChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={true} />
                        </Grid>
                    </Grid>

                    {isDisplay && <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <TotalOnlineDevicesChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={true} />
                        </Grid>
                    </Grid>
                    }
                    {isDisplay && <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <TotalRegisteredDeviceChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={true} />
                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} p={1}
                    >
                        <Grid style={{ marginLeft: '1rem', border: '1px solid #70707026', boxShadow: '0px 0px 6px #00000029', borderRadius: '8px' }}>
                            <TotalCompaniesChart fromDate={startDate} toDate={endDate} applicationId={applId === 'All Applications' ? '' : applId} isTotal={true} />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}