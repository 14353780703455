import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import ReactECharts from 'echarts-for-react';
import React from "react";
import { graphic } from "echarts";
import registeredDevicesService from "../actions/registeredDevicesService";

export default function TotalRegisteredDeviceChart(props) {
    const [dateRange, setDateRange] = React.useState('daily');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [option, setOption] = React.useState({
        grid: {
            top: 35,
            bottom: 50,
            left: 80,
            right: 80
        },
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
        },
        yAxis: {
            type: 'value',
        },
        legend: {
            data: ['Pre-Registered Devices', 'Registered Devices', 'UnRegistered Devices'],
            // align: 'left',
            // right: '1%',
            textStyle: {
                fontSize: 8,
            }
        },
        series: [
            {
                name: 'Pre-Registered Devices',
                type: 'line',
                showSymbol: false,
                data: [],
            },
            {
                name: 'Registered Devices',
                type: 'line',
                showSymbol: false,
                data: [],
            },
            {
                name: 'UnRegistered Devices',
                type: 'line',
                showSymbol: false,
                data: [],
            },
        ]
    });

    React.useEffect(() => {
        let userData;
        if ((props.fromDate !== null && props.toDate === null) || (props.fromDate === null && props.toDate !== null)) {

        } else {
            const payload = {
                query: dateRange,
                isTotal: props.isTotal,
                fromDate: props.fromDate,
                toDate: props.toDate,
                applicationId: '',
            };
            registeredDevicesService.getRegisteredDevicesList(payload).then((data) => {
                userData = data.data ? data.data : [];
                const maxValue = Math.max(...userData.map((user) => (user.count)));
                const splitNUmber = maxValue < 1 ? 1 : maxValue >= 10 ? 10 : maxValue;

                setOption({
                    ...option,
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            rotate: 45,
                            textStyle: {
                                fontSize: 7,
                            },
                        },
                        data: dateRange === 'daily' ?
                            userData.map(item => item.day).reverse() :
                            dateRange === 'monthly' ? userData.map(item => (item.month.slice(0, 4) + '-' + monthNames[item.month.slice(-2) - 1])).reverse() :
                                dateRange === 'quarterly' ? userData.map(item => item.quarter).reverse() : userData.map(item => item.year).reverse()
                    },
                    yAxis: {
                        type: 'value',
                        splitNumber: splitNUmber
                    },
                    legend: {
                        data: ['Pre-Registered Devices', 'Registered Devices', 'Unregistered Devices'],
                        // align: 'left',
                        // right: '1%',
                        textStyle: {
                            fontSize: 8,
                        }
                    },
                    series: [
                        {
                            name: 'Pre-Registered Devices',
                            data: dateRange === 'daily' ?
                                userData.map(item => item.count) :
                                dateRange === 'monthly' ? userData.map(item => item.count) :
                                    dateRange === 'quarterly' ? userData.map(item => item.count) : userData.map(item => item.count),

                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#55AAD6'
                                    },
                                    {
                                        offset: 1,
                                        color: '#55AAD6'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },

                            endLabel: {
                                show: true,
                                formatter: function (params) {
                                    return params.value;
                                }
                            },
                        },
                        {
                            name: 'Registered Devices',
                            data: dateRange === 'daily' ?
                                userData.map(item => item.soldOutCount) :
                                dateRange === 'monthly' ? userData.map(item => item.soldOutCount) :
                                    dateRange === 'quarterly' ? userData.map(item => item.soldOutCount) : userData.map(item => item.soldOutCount),

                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#4BB41F'
                                    },
                                    {
                                        offset: 1,
                                        color: '#4BB41F'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },

                            endLabel: {
                                show: true,
                                formatter: function (params) {
                                    return params.value;
                                }
                            },
                        },
                        {
                            name: 'Unregistered Devices',
                            // type: 'line',
                            // smooth: true,
                            // color: "#F59B41",
                            // symbol: 'circle',
                            data: dateRange === 'daily' ?
                                userData.map((item, index) => (item.count - item.soldOutCount)) :
                                dateRange === 'monthly' ? userData.map((item, index) => (item.count - item.soldOutCount)) :
                                    dateRange === 'quarterly' ? userData.map((item, index) => (item.count - item.soldOutCount)) : userData.map((item, index) => (item.count - item.soldOutCount)),

                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#F59B41'
                                    },
                                    {
                                        offset: 1,
                                        color: '#F59B41'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            endLabel: {
                                show: true,
                                formatter: function (params) {
                                    return params.value;
                                }
                            },
                        }
                    ],
                })
            })
        }
    }, [dateRange, props.fromDate, props.toDate]);

    return (
        <div>
            <Box display='flex' flexDirection='row'>
                <Box flexGrow={1}>
                    <Typography gutterBottom variant='h6' component='h6' style={{ fontSize: '16px', paddingLeft: '14px', color: '#05568D', opacity: '1px', marginLeft: '3px', marginTop: '15px' }}>
                        {('Total Registered Devices')}
                    </Typography>
                </Box>
                <Box p={1} width='90px'>
                    <FormControl variant='outlined' size='small' fullWidth>
                        {/* <InputLabel id='demo-simple-select-outlined-label'>{('Date range')}</InputLabel> */}
                        <Select
                            value={dateRange}
                            labelId='demo-simple-select-outlined-label'
                            id='demo-simple-select-outlined'
                            onChange={(e) => setDateRange(e.target.value)}
                            style={{ color: "#707070", opacity: '1px', borderRadius: '20px', fontSize: '12px' }}
                        >
                            <MenuItem value={'daily'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Daily')}</MenuItem>
                            <MenuItem value={'monthly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Monthly')}</MenuItem>
                            <MenuItem value={'quarterly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Quarterly')}</MenuItem>
                            <MenuItem value={'yearly'} style={{ color: "#707070", opacity: '1px', fontSize: '12px' }}>{('Yearly')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <ReactECharts option={option} />
        </div>
    )
}